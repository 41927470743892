<template>
    <div v-loading="loading">
      <component
        v-if="mappedField && mappedField.components"
        v-bind:is="mappedField.components.edit"
        :data="mappedField"
        :fieldsData="fieldsData"
        :field="field"
        :selfTemplateId="selfTemplateId"
      ></component>
    </div>
  </template>
  
  <script>
  import dataTableAllowedFields from "./index";
  var fieldsList = require("../fields.json");
  
  export default {
    data() {
      return {
        fieldActions: [],
        fieldComponentsMap: {},
        mappedField: {},
        loading: false,
      };
    },
    props: ["fieldsData", "field", "selfTemplateId"],
    components: {
      ...dataTableAllowedFields,
    },
    mounted() {
      this.loading = true;
      if (fieldsList) {
        this.fieldActions = [
          ...fieldsList.allFormFields,
          ...fieldsList.allContentFields,
          ...fieldsList.allAdvancedFields,
        ];
      }
      if (this.fieldActions) {
        this.fieldActions.forEach((field) => {
          if (!this.fieldComponentsMap[field.inputType]) {
            this.fieldComponentsMap[field.inputType] = field.components;
          }
        });
      }
      this.mappedField = { ...this.field };
      if (!this.mappedField.components) {
        this.$set(
          this.mappedField,
          "components",
          this.fieldComponentsMap[this.field.input_type]
        );
      }
      if (this.mappedField?.properties?.filed_content == "Hide") {
        this.mappedField.properties.filed_content = "";
      }
      setTimeout(() => {
        this.loading = false;
      }, 100);
    },
    methods: {},
  };
  </script>
  
  <style></style>
  